import WebIM from 'agora-chat'
import {rtcProps, styles} from "../../core/constants";
import React, {useEffect, useRef, useState} from "react";
import Axios from "axios";
import ReplayIcon from '@mui/icons-material/Replay';
import {IconButton} from "@mui/material";
import * as _ from 'lodash';

const conn = new WebIM.connection({
  appKey: rtcProps.chatAppKey,
});

const ChatRoom = (props: any) => {
  const bottomRef = useRef<null | HTMLElement>(null);

  const [userId, setUserId] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [peerMessage, setPeerMessage] = useState('');
  const [messages, setMessages] = useState([] as any);

  useEffect(() => {
    console.log('messages', messages);
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [messages])

  useEffect(() => {
    connectChat();
  }, [])

  conn.addEventHandler("connection&message", {
    onConnected: () => {
      console.log('onConnected~~~~')
      conn.joinChatRoom({
        roomId: props.roomId,
        message: 'Stream Chat'
      }).then(res => {
        setUserStatus('Connected');
        console.log('JoinedRoom- ', res);
      }).catch(e => {
        setUserStatus('Failed!');
        console.log('Error JoiningRoom- ', e);
      })
    },
    onDisconnected: () => {
      try {
        conn.leaveChatRoom({roomId: props.roomId}).catch(e => {
          console.log('Error LeavingRoom-', e);
        }).then(() => {
          setUserStatus('Disconnected!');
        }).catch(() => {
          setUserStatus('Disconnected!');
        })
      } catch (e) {}
    },
    onTextMessage: (message: any) => {
      console.log('onTextMessage', message, messages);
      setMessages([...messages, {
        id: message.id,
        text: message.msg,
        from: message.from
      }]);
      },
    onTokenWillExpire: () => {
      // setUserStatus('TokenWillExpire');
    },
    onTokenExpired: () => {
      setUserStatus('TokenExpired!');
    },
    onError: (error) => {
      console.log("on error", error);
    },
  });

  const connectChat = () => {
    Axios.post("https://agorastream.softuvo.click/generateChatToken",{
      username: props.username
    }).then(async (tokenRes: any) => { // userToken, username, uuid
      console.log('tokenRes~~', tokenRes);
      if (tokenRes.status === 200) {
        const tokenResData = tokenRes.data;
        loginUser(tokenResData.username, tokenResData.userToken)
      }
    }).catch((e) => {
      console.log('Error generateChatToken', e);
    });
  }
  const loginUser = (id: string, token: string) => {
    conn.open({
      user: id,
      agoraToken: token,
    }).catch(e => {
      console.log('Error logging in...~~~~', e);
    });
    setUserId(id);
  }
  const logoutUser = () => {
    conn.close();
  }

  const onSendMessage = () => {
    if (peerMessage) {
      let option = {
        chatType: 'chatRoom', // "singleChat",
        type: "txt",
        to: props.roomId,
        msg: peerMessage
      } as any;

      conn.send(WebIM.message.create(option)).then((res) => {
        console.log("send private text success", res);
        setPeerMessage('');
        setMessages([...messages, {
          id: res.serverMsgId,
          text: peerMessage,
          from: userId
        }]);
      }).catch((e) => {
        console.log("send private text fail", e);
        setPeerMessage('');
      });
    }
  }

  const getChatList = () => {
    let content = [];
    const uniqueMessagesList = _.uniqBy(messages, 'id');
    if (uniqueMessagesList.length) {
      for (let item of (uniqueMessagesList as any)) {
        content.push(
          <li className={`mb-3 d-flex ${item.from === userId ? 'flex-row-reverse align-items-end':'flex-row  align-items-end'}`} key={item.id}>
            <div className="max-width-70 text-right">
              <div className={`card border-0 p-2 chatBox ${item.from === userId ? 'right-chat text-light':'left-chat'}`}>
                <p className="messageName">{item.from}</p>
                <div className="message">{item.text}</div>
              </div>
            </div>
          </li>
        );
      }
    } else {
      content.push(
        <li style={styles.noMessagesDiv} className="mb-3" key={'noMessagesId'}>
          <div className="max-width-70 text-center">
            <div className={`card border-0 p-1 center-chat text-light`}>
              <div className="message">No message found!</div>
            </div>
          </div>
        </li>
      )
    }
    return content;
  };
  return (
    <>
      <div className="col-lg-4 col-md-12">
        <div className="card card-chat-body border-0 w-100">
          <div className="chat-header justify-content-between align-items-center border-bottom pb-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="ms-3">
                <h6 className="mb-0">Chat</h6>
                <div id="statusUser">{userStatus}</div>
              </div>
              {(userStatus && userStatus !== 'Connected') && <div className="ms-3">
                <IconButton aria-label="replay" onClick={connectChat}>
                  <ReplayIcon fontSize="inherit" />
                </IconButton>
              </div>}
            </div>
          </div>
          <ul className="chat-history list-unstyled mb-0 py-lg-5 py-md-4 py-3 flex-grow-1" id={'chatUl'}>
            {getChatList()}
            <div ref={bottomRef as React.RefObject<HTMLDivElement>} />
          </ul>
          <div className="chat-message">
            <button className="btn btn-dark" type="button" onClick={onSendMessage}>Send</button>
            <textarea required className="form-control" placeholder="Enter text here..." value={peerMessage} onChange={(e) => {
              setPeerMessage(e.target.value)
            }}></textarea>
          </div>
        </div>
        {/*<form id="chat-loginBtns">
          <button type="button" onClick={e => loginUser('user_1676271799617', rtcProps.user103Token)}>Login As uid 103</button>
          <button type="button" onClick={e => loginUser('user_1676271799611', rtcProps.user104Token)}>Login As uid 104</button>
          <button type="button" onClick={logoutUser}>Logout</button>
        </form>*/}
      </div>
    </>
  )
}
export default ChatRoom;
