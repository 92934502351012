import {rtcProps, styles} from "../../core/constants";
import AgoraUIKit, {layout} from "agora-react-uikit";
import React from "react";


const Stream = (props: any) => {

  let channelParameters: any = {
    remoteAudioTrack: null,
    remoteVideoTrack: null,
    remoteUid: null
  };

  /*async function startBasicCall(channel: string, token: string) {
   setVideoCall(true);
   const agoraEngine = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
   await agoraEngine.setClientRole('audience');
   const remotePlayerContainer = document.getElementById("streamView") as HTMLElement;

   /!*remotePlayerContainer.style.width = "340px";
   remotePlayerContainer.style.height = "280px";
   remotePlayerContainer.style.border = "1px solid gray";
   remotePlayerContainer.style.padding = "15px 5px 5px 5px";*!/

   agoraEngine.on("user-published", async (user, mediaType) => {
     await agoraEngine.subscribe(user, mediaType);
     console.log("subscribe success", mediaType);
     if (mediaType === "video") {
       channelParameters.remoteVideoTrack = user.videoTrack;
       channelParameters.remoteAudioTrack = user.audioTrack;
       channelParameters.remoteUid = user.uid.toString();


       remotePlayerContainer.setAttribute('id', user.uid.toString());
       // remotePlayerContainer.textContent = "Remote user " + user.uid.toString();
       // Append the remote container to the page body.
       // document.body.append(remotePlayerContainer);

       channelParameters.remoteVideoTrack.play(remotePlayerContainer);

     }
     if (mediaType === "audio") {
       channelParameters.remoteAudioTrack = user.audioTrack;
       channelParameters.remoteAudioTrack.play();
     }

     agoraEngine.on("user-unpublished", user => {
       console.log(user.uid+ "has left the channel");
     });
   });
   console.log('channe', channel)
   await agoraEngine.join(rtcProps.appId, channel, token, 0);
   channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
   channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack();


   window.onload = function () {
     // @ts-ignore
     document.getElementById('leaveStream').onclick = async function () {
       channelParameters.localAudioTrack.close();
       channelParameters.localVideoTrack.close();
       // Remove the containers you created for the local video and remote video.
       removeVideoDiv(remotePlayerContainer.id);
      // Leave the channel
       await agoraEngine.leave();
       console.log("You left the channel");
       // Refresh the page for reuse
       window.location.reload();
     }
     // @ts-ignore
     document.getElementById('Audience').onclick = async function ()
     {
       // @ts-ignore
       if (document.getElementById('Audience').checked)
       {
         if(channelParameters.localAudioTrack != null && channelParameters.localVideoTrack != null)
         {
           // Unpublish local tracks to set the user role as audience.
           await agoraEngine.unpublish([channelParameters.localAudioTrack,channelParameters.localVideoTrack]);
           // Stop playing the local video track
           channelParameters.localVideoTrack.stop();
           if(channelParameters.remoteVideoTrack!=null)
           {
             // Play the remote video stream, if the remote user has joined the channel.
             channelParameters.remoteVideoTrack.play(remotePlayerContainer);
           }
         }
         // Call the method to set the role as Audience.
         await agoraEngine.setClientRole('audience' as ClientRole);
       }
     }
   }
 }*/



  return (
  <>
    <div className="col-lg-4 col-md-12">
      <div className="streamSection">
        {/*<div className="streamView"></div>
                    <button className="leaveStream" id={'leaveStream'}>Leave</button>*/}
        <div style={styles.container}>
          <AgoraUIKit
            rtcProps={{
              appId: rtcProps.appId,
              channel: props.channelName,
              token: props.token,
              role: 'audience',
              layout: layout.grid,
              uid: 0
            }}
            rtmProps={{ username: props.username || 'User', displayUsername: !!(props.username) }}
            callbacks={{
              EndCall: () => props.setVideoCall(false)
            }}
          />
          <span className="views"><i className="fa-regular fa-eye"></i> You're an audience ({props.username}) </span>
        </div>
      </div>
    </div>
  </>
)
}
export default Stream;
