import {CSSProperties} from "react";

export const rtcProps = {
  appId: "917fd91f5e2149eea9c592c024b93475",
  appCertificate: "7ca527b6a53a4720a38473d2444feeba",
  appCertificateSecondary: "4fdfe8ce3255403bbd9da46c4847dfbd",
  chatAppKey: '61881763#1060324',
};

export const styles = {
  noMessagesDiv: {
    display: 'table !important',
    margin: '0 auto'
  },
  'center-chat': {
    'background-color': '#FF6252',
  },
  'center-chat .messageName': {
    margin: '0 auto',
    display: 'table',
    color: '#fff',
    'font-weight': 600
  },
  container: {
    height: '100vh',
    display: 'flex',
    flex: 1,
    // backgroundColor: '#007bff22'
  },
  heading: { textAlign: 'center' as const, marginBottom: 0 },
  videoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  } as CSSProperties,
  nav: { display: 'flex', justifyContent: 'space-around' },
  btn: {
    backgroundColor: '#007bff',
    cursor: 'pointer',
    borderRadius: 5,
    padding: '4px 8px',
    color: '#ffffff',
    fontSize: 20
  },
  input: { display: 'flex', height: 24, alignSelf: 'center' } as CSSProperties
}
